import { setupSwissTopoMap } from "../lib/swisstopo";
import styles from "ol/ol.css?url";

const style = `
<style>
#map {
  width: 100%;
  height: 32rem;
}

@media screen and (min-width: 768px) {
  #map {
    height: 100%;
    aspect-ratio: 3 / 2;
  }
}

.ol-popup {
  position: absolute;
  background-color: white;
  padding: 5px;
  border: 1px solid #000000;
  bottom: 5px;
  left: -50px;
  min-width: 300px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}

.control {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 400px;
  padding: 5px;
  background: #91aeff;
  z-index: 1;
  border: 1px solid #000000;
}

#iframe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

table {
  font-size: 80%;
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #DDD;
}

tr:hover {
  background-color: #D6EEEE;
}
</style>
`;

export class SwisstopoMap extends HTMLElement {
  // Static methods
  static tagName = "swisstopo-map";

  static register(tagName = this.tagName, registry) {
    if ("customElements" in window) {
      (registry || customElements).define(tagName, this);
    }
  }

  // Instance methods
  connectedCallback() {
    // We have to double parse this, since we first unescape the html, and then actually parse the json
    const jsonData = JSON.parse(JSON.parse(this.dataset.mapJson));
    this.shadow = this.attachShadow({ mode: "closed" });
    this.shadow.innerHTML = this.render();
    setupSwissTopoMap(jsonData, this.shadow);
  }

  render() {
    return `
      ${style}
      <link href="${styles}" rel="stylesheet"></link>
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ" crossorigin="anonymous"></link>
      <div id="control" class="control" style="z-index: 1;">
        <h4 id="titleData"></h4>
        <p id="informationData"></p>
        <p id="statistiqueData"></p>
      </div>
      <div id="map" style="min-height: 100px; min-width: 100px"></div>
      <div id="popup" class="ol-popup">
        <a href="#" id="popup-closer" class="ol-popup-closer"></a>
        <div id="popup-content"></div>
      </div>
    `;
  }
}
